<script>
import { nextTick } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import CartItem from '/~/components/cart-menu/cart-item.v3.vue'
import CartHomeV1 from '/~/components/cart-menu/views/home/cart-home.vue'
import { useAccess } from '/~/composables/access/use-access'
import { useCart } from '/~/composables/cart'
import { usePurchaseCheckout } from '/~/composables/checkout'

export default {
  name: 'cart-menu-home-v3',
  components: {
    CartItem,
  },
  extends: CartHomeV1,
  setup() {
    const { isCartLoading } = useCart()
    const { cartAmount } = usePurchaseCheckout()

    return {
      ...CartHomeV1.setup?.(...arguments),
      isCartLoading,
      cartAmount,
      formatDollar,
    }
  },
  mounted() {
    nextTick(() => {
      this.$refs.closeButton.$el.focus()
    })
  },
}
</script>

<template>
  <div
    class="flex h-full w-full flex-col bg-eonx-neutral-50"
    @keyup.esc="close"
  >
    <div
      :class="[
        'flex h-full w-full flex-shrink-0 flex-grow flex-col',
        isStandalone && 'footer-extra',
      ]"
    >
      <div class="z-1 bg-white px-5 py-6 text-eonx-neutral-800 shadow">
        <div class="flex items-center">
          <base-button
            ref="closeButton"
            icon="base/chevron"
            :size="28"
            :padding="5"
            alt="close"
            title="Cart"
            @click="close"
          />
          <div class="flex flex-grow items-center justify-center truncate">
            <h2 class="text-xl leading-7 sm:leading-6">
              Cart
              {{ cartQuantity > 0 ? `(${cartQuantity})` : null }}
            </h2>
          </div>
        </div>
      </div>

      <div
        v-if="cartIsEmpty"
        class="flex flex-1 flex-col items-center bg-eonx-neutral-50 px-6 text-center"
      >
        <div class="mb-5 mt-20 text-xl font-bold text-eonx-neutral-800">
          Your cart is currently empty
        </div>
        <div
          class="flex w-full max-w-[400px] items-center rounded-lg border bg-white px-6 py-5"
        >
          <div
            class="mr-5 flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full bg-primary text-white"
          >
            <base-icon svg="symbion/cart" :size="32" />
          </div>
          <div class="flex-grow">
            <div class="h-6 w-1/2 rounded-md bg-eonx-neutral-300" />
            <div class="mt-2.5 h-6 w-full rounded-md bg-eonx-neutral-50" />
          </div>
        </div>
      </div>
      <div
        v-else
        class="h-full space-y-4 overflow-y-auto bg-eonx-neutral-50 pt-[5px]"
      >
        <cart-item
          v-for="(item, idx) in cartItemsFiltered"
          :key="idx"
          :item="item"
          data-test="cart-item"
          :data-test-id="item.rowId"
        />
      </div>
      <div
        v-if="!cartIsEmpty"
        class="left-0 z-1 mt-auto w-full px-5 py-5 shadow-lg"
      >
        <base-button
          :disabled="isCartLoading || isCheckingIdentityVerification"
          :loading="isCheckingIdentityVerification"
          full-width
          @click="withAccessCheck({ callback: onCheckout })"
        >
          <span class="flex items-center">Checkout</span>
        </base-button>
      </div>
    </div>
  </div>
</template>
